import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConditionFilter, ProductModel, ProductState } from "./type";
import { fetchDiscountedProducts, fetchOneProduct, fetchProductsFromBrand, fetchProductsFromCategory } from "./thunk";
import { groupProductsByProductCode } from "./helpers";

const initialState: ProductState = {
	products: [],
	productsToShow: [],
	conditionFilter: 'new',
	loading: "pending",
	clientCacheProductIds: [],
};

export const productSlice = createSlice({
	name: "product",
	initialState,
	reducers: {
		toggleProductFavorite(state, action: PayloadAction<number>) {
			const item = state.products.find(item => item.id === action.payload);
			if (item) {
				item.isFavorite = !item.isFavorite;

				localStorage.setItem(
					`product_${item.id}_isFavorite`,
					JSON.stringify(item.isFavorite)
				);
			}
		},
		setConditionFilter(state, action: PayloadAction<ConditionFilter>) {
			state.conditionFilter = action.payload;
		},
		setClientCacheProductIds(state, action: PayloadAction<number>) {
			if (!state.clientCacheProductIds.includes(action.payload)) {
				state.clientCacheProductIds.push(action.payload);
			}
		},
		clearClientCacheProductIds(state) {
			state.clientCacheProductIds = []
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchProductsFromCategory.pending, state => {
				state.loading = "pending";
				state.products = [];
			})
			.addCase(
				fetchProductsFromCategory.fulfilled,
				(state, action: PayloadAction<ProductModel[]>) => {
					state.loading = "success";
					
					const result = action.payload.map(product => {
						const isFavorite = JSON.parse(
							localStorage.getItem(`product_${product.id}_isFavorite`) ||
								"false"
						);
						return { ...product, isFavorite: isFavorite };
					});

					state.products = result;
					state.productsToShow = groupProductsByProductCode(result);
				}
			)
			.addCase(fetchProductsFromCategory.rejected, state => {
				state.loading = "error";
				state.products = [];
			})
			.addCase(fetchProductsFromBrand.pending, state => {
				state.loading = "pending";
				state.products = [];
			})
			.addCase(
				fetchProductsFromBrand.fulfilled,
				(state, action: PayloadAction<ProductModel[]>) => {
					state.loading = "success";
					
					const result = action.payload.map(product => {
						const isFavorite = JSON.parse(
							localStorage.getItem(`product_${product.id}_isFavorite`) ||
								"false"
						);
						return { ...product, isFavorite: isFavorite };
					});

					state.products = result;
					state.productsToShow = groupProductsByProductCode(result);
				}
			)
			.addCase(fetchProductsFromBrand.rejected, state => {
				state.loading = "error";
				state.products = [];
			})
			.addCase(fetchOneProduct.pending, state => {
				state.loading = "pending";
				state.products = [];
			})
			.addCase(
				fetchDiscountedProducts.fulfilled,
				(state, action: PayloadAction<ProductModel[]>) => {
					state.loading = "success";
					const products = action.payload.map(product => {
						const isFavorite = JSON.parse(
							localStorage.getItem(`product_${product.id}_isFavorite`) ||
								"false"
						);
						return { ...product, isFavorite: isFavorite };
					});

					state.products = products;
					state.productsToShow = groupProductsByProductCode(products);
				}
			)
			.addCase(fetchDiscountedProducts.rejected, state => {
				state.loading = "error";
				state.products = [];
			})
			.addCase(fetchDiscountedProducts.pending, state => {
				state.loading = "pending";
				state.products = [];
			})
			.addCase(
				fetchOneProduct.fulfilled,
				(state, action: PayloadAction<ProductModel>) => {
					state.loading = "success";
					const isFavorite = JSON.parse(
						localStorage.getItem(`product_${action.payload.id}_isFavorite`) ||
							"false"
					);
					const product = {
						...action.payload,
						isFavorite,
					}
					
					state.products = [product];
				}
			)
			.addCase(fetchOneProduct.rejected, state => {
				state.loading = "error";
				state.products = [];
			});
	},
});

export const { toggleProductFavorite, setConditionFilter } = productSlice.actions;
export const { setClientCacheProductIds, clearClientCacheProductIds } = productSlice.actions;

export default productSlice.reducer;
