import React, { useEffect, useRef, useState, useCallback } from "react";
import "./Sticky.scss";

interface StickyProps {
  children: React.ReactNode;
  className?: string;
  stickyClass?: string;
}

const Sticky: React.FC<StickyProps> = ({ children, className = "", stickyClass = "sticky_top" }) => {
  const [currentState, setCurrentState] = useState<"STATE_STICKY_TOP" | "STATE_FLOATING">("STATE_FLOATING");
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);
  const [firstScrollDown, setFirstScrollDown] = useState<boolean>(false);
  const [firstScrollUp, setFirstScrollUp] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const setState = (state: "STATE_STICKY_TOP" | "STATE_FLOATING") => {
    if (currentState !== state && containerRef.current) {
      setCurrentState(state);

      if (state === "STATE_STICKY_TOP") {
        containerRef.current.classList.add(stickyClass);
      } else if (state === "STATE_FLOATING") {
        containerRef.current.classList.remove(stickyClass);
      }
    }
  };

  const setPositionTop = (top: number) => {
    if (containerRef.current && top >= 0) {
      containerRef.current.style.top = `${top}px`;
    }
  };

  const handleScrollDown = useCallback((scrollTop: number) => {
    if (!containerRef.current) return;

    if (!firstScrollDown) {
      if (currentState === "STATE_STICKY_TOP") {
        setPositionTop(scrollTop);
      }
      setState("STATE_FLOATING");
      setFirstScrollDown(true);
      setFirstScrollUp(false);
    }
  }, [firstScrollDown, currentState]);

  const handleScrollUp = useCallback((scrollTop: number) => {
    if (!containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();

    if (firstScrollUp && currentState === "STATE_FLOATING" && rect.bottom < 0) {
      setPositionTop(scrollTop - containerRef.current.clientHeight);
    }

    if (currentState !== "STATE_STICKY_TOP" && rect.top >= 0) {
      setState("STATE_STICKY_TOP");
      setPositionTop(0);
    }

    setFirstScrollUp(true);
    setFirstScrollDown(false);
  }, [firstScrollUp, currentState]);

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    if (lastScrollTop !== undefined) {
      if (scrollTop > lastScrollTop) {
        handleScrollDown(scrollTop);
      } else {
        handleScrollUp(scrollTop);
      }
    }

    setLastScrollTop(scrollTop);
  }, [lastScrollTop, handleScrollDown, handleScrollUp]);

  const handleUpdateRequest = useCallback((forceFloating: boolean = false) => {
    if (!containerRef.current) return;

    if (forceFloating) {
      setPositionTop(0);
      setCurrentState("STATE_FLOATING");
    } else {
      handleScroll();
    }
  }, [handleScroll]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div ref={containerRef} className={className ? `${className} side_bar` : "side_bar"}>
      {children}
    </div>
  );
};

export default Sticky;