import type { FC } from "react";
import classes from "./Order.module.scss";
import { Animated } from "../../shared/components/Animated/Animated";
import clsx from "clsx";
import { useMatchMedia } from "../../shared/hooks/useMatchMedia";

export const IndividualOrder: FC = () => {
    const { isMobile }: any = useMatchMedia();

    const text = ' официальный телеграмм канал о культуре и моде';

	return (
		<Animated>
			<div className={classes.wrapper}>
				{isMobile ? (
					<div className={classes.content}>
						<div className={classes.image}>
							<img
								src="images/individual-order.svg"
								alt="Индивидуальный заказ"
								width="60%"
								height="60%"
							/>
						</div>

						<div className={clsx(
							classes.heading
						)}>
							<h1>доставка эксклюзивных вещей из любых онлайн и офлайн бутиков Европы</h1>
						</div>

						<div className={clsx(
							classes.description,
						)}>
							<p>man I trust предоставляет возможность приобретать товары модных домов с доставкой до вашей двери в рекордно короткие сроки</p>
							<p>для заказа вам достаточно поделиться с нами ссылкой на желаемый товар или его фотографией и персональный менеджер ответит вам в течение нескольких минут</p>
							<p>наша команда найдет для вас даже самые труднодоступные товары и организует бесплатную доставку в Россию</p>
						</div>

						<div className={clsx(
							classes.heading
						)}>
							<h1>менеджер:</h1>
							<h1><a  href="http://t.me/manitrusty" target="_blank" rel="noreferrer" className={classes.social}>telegram</a> / <a href="http://wa.me/79691122020"  target="_blank" rel="noreferrer"  className={classes.social}>whatsapp</a></h1>
						</div>
					</div>
				) : (
					<div className={classes.content}>
						<div className={classes.image}>
							<img
								src="images/individual-order.svg"
								alt="Индивидуальный заказ"
								width="85%"
								height="85%"/>
						</div>
						<div className={classes.text}>
							<div className={clsx(
								classes.column,
								classes.heading
							)}>
								<h1>доставка эксклюзивных вещей из любых онлайн и офлайн бутиков Европы</h1>
								<h1>менеджер:  <a href="http://t.me/manitrust" target="_blank" rel="noreferrer"  className={classes.social}>telegram</a> / <a href="http://wa.me/79691122020" target="_blank" rel="noreferrer"  className={classes.social}>whatsapp</a></h1>
							</div>
							<div className={clsx(
								classes.column,
								classes.description,
							)}>
								<p>man I trust предоставляет возможность приобретать товары модных домов с доставкой до вашей двери в рекордно короткие сроки</p>
								<p>для заказа вам достаточно поделиться с нами ссылкой на желаемый товар или его фотографией и персональный менеджер ответит вам в течение нескольких минут</p>
								<p>наша команда найдет для вас даже самые труднодоступные товары и организует бесплатную доставку в Россию</p>
							</div>
						</div>
					</div>
				)}

				<a href="https://t.me/manitrust">
					<div className={clsx(
						classes.runningLine,
						'hoverDiv'
						)}>
						{[...Array(3).keys()].map(() => {
							return (
									<div className={classes.piece}>
											<h1>{text}</h1>
											<button className={classes.button}>
												взглянуть
											</button>
									</div>
							)
						})}
					</div> 
				</a>
			</div>
		</Animated>
	);
};
