import { type FC } from "react";
import classes from "./Footer.module.scss";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { Icon } from "../../shared/Icon/Icon";
import clsx from "clsx";
import { useMatchMedia } from "../../shared/hooks/useMatchMedia";

export interface FooterType {
	name?: string;
	a?: string;
	id: number;
	links: string;
}

export const Footer: FC = () => {
	const { isMobile }: any = useMatchMedia();

	const footer = [
		{ name: "доставка и оплата", id: 1, links: "/delivery" },
		{ name: "возврат", id: 2, links: "/back" },
		{ name: "гарантия подлинности", id: 3, links: "/authenticity" },
		{ name: "часто задаваемые вопросы", id: 4, links: "/questions", isDisabled: true },
		{ name: "система лояльности", id: 5, links: "/" },

		{ name: "о нас", id: 6, links: "/about-us" },
		{ name: "контакты", id: 7, links: "/about-us" },
		{ name: "пользовательское соглашение", id: 8, links: "/user-agreement" },
		{ name: "политика конфиденциальности ", id: 10, links: "/privacy-policy" },

		{
			a: "instagram",
			id: 1,
			links:
				"https://www.instagram.com/manitrusty",
		},
		{ a: "telegram", id: 1, links: "http://t.me/manitrust" },
		{ a: "whatsapp", id: 1, links: "http://wa.me/79691122020" },
		{ a: "8 (969) 112-20-20", links: "tel:89691122020", id: 1 },
		{ a: "hi@manitrust.ru", links: "mailto:hi@manitrust.ru", id: 1 },
	];

	const footerColumn1 = footer.slice(0, 5);
	const footerColumn2 = footer.slice(5, 9);
	const footerColumn3 = footer.slice(9, 14);

	return (
		<div className={classes.wrapper}>
			<div className={classes.content}>

				{isMobile ? (
					<>
						<div className={classes.row}>
							<div className={classes.column}>
								{footerColumn1.map(({ name, links, isDisabled }) => (
									<div className={classes.links} key={uuidv4()}>
										{isDisabled ? (
											<span>{name}</span>
										) : (
											<Link className={classes.link} to={links}>
												{name}
											</Link>
										)}
									</div>
								))}
							</div>
							<div className={classes.column}>
								{footerColumn2.map(({ name, links }) => (
									<div className={classes.links} key={uuidv4()}>
										<Link className={classes.link} to={links}>
											{name}
										</Link>
									</div>
								))}
							</div>
						</div>

						<div className={classes.row}>
							<div className={classes.column}>
								{footerColumn3.map(({ a, links }) => (
									<div className={classes.links} key={uuidv4()}>
										<a
											className={classes.link}
											href={links}
											target="_blank"
											rel="noreferrer"
										>
											{a}
										</a>
									</div>
								))}
							</div>

							<div className={clsx(
								classes.column,
								classes.last
								)}>
								<Link className={classes.cooperation} to="/cooperation">
									сотрудничество
								</Link>

								<Link to="/">
									<Icon name={"logo"} size={90} className={classes.logo}/>
								</Link>
							</div>
						</div>

						<div className={classes.end}>
							<span className={classes.protect}>
								все права защищены 2025, man I trust
							</span>
						</div>
					</>
				) : (
					<div className={classes.row}>
						<div className={classes.column}>
								{footerColumn1.map(({ name, links, isDisabled }) => (
									<div className={classes.links} key={uuidv4()}>
										{isDisabled ? (
											<span>{name}</span>
										) : (
											<Link className={classes.link} to={links}>
												{name}
											</Link>
										)}
									</div>
								))}
							</div>
							<div className={classes.column}>
								{footerColumn2.map(({ name, links }) => (
									<div className={classes.links} key={uuidv4()}>
										<Link className={classes.link} to={links}>
											{name}
										</Link>
									</div>
								))}
							</div>

							<div className={classes.column}>
								{footerColumn3.map(({ a, links }) => (
									<div className={classes.links} key={uuidv4()}>
										<a
											className={classes.link}
											href={links}
											target="_blank"
											rel="noreferrer"
										>
											{a}
										</a>
									</div>
								))}
							</div>

						<div className={classes.column}>
							<Link to="/cooperation">
								сотрудничество
							</Link>
						</div>

						<div className={clsx(
							classes.column,
							classes.last
							)}>
								<Link to="/">
									<Icon name={"logo"} size={90} className={classes.logo}/>
								</Link>

								<span className={classes.protect}>
									все права защищены 2025, man I trust
								</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
