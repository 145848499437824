import type { FC } from "react";
import classes from "./Thankfull.module.scss";
import { Animated } from "../../shared/components/Animated/Animated";

export const Thankfull: FC = () => {
	return (
		<Animated className={classes.wrapper}>
				<div className={classes.content}>
					{/* <h1 className={classes.title}>благодарность за покупку</h1> */}
					<div className={classes.text}>
						<span className={classes.first}>
							благодарим за заказ. будьте на связи, мы свяжемся с вами и согласуем время доставки.
						</span>
						{/* <span className={classes.second}>
							Для проверки подлинности мы проводим собственную экспертизу и
							используем надежные сервисы, такие как Entrupy, Authenticate
							First, Authentic, Bababebi, в зависимости от модели и бренда. Эти
							платформы аутентификации определяют подделки с высочайшей
							точностью и работают с самыми известными.
						</span> */}
					</div>
					<div className={classes.footer}>
						{/* <span className={classes.pleasure}>c уважением, man i trust</span> */}
						<div className={classes.links}>
							<div className={classes.link}>
								instagram:
								<a href="https://www.instagram.com/manitrusty" target="_blank">
									@manitrusty
								</a>
							</div>
							<div className={classes.link}>
								telegram:
								<a href="https://t.me/manitrust" target="_blank">
									t.me/manitrust
								</a>
							</div>
						</div>
					</div>
				</div>
		</Animated>
	);
};
