import { useRef, type FC, type ReactNode, useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import clsx from "clsx";
import classes from "./Animated.module.scss";

export interface AnimatedProps {
	children: ReactNode;
	className?: string;
	animate?: boolean;
}
export interface AnimatedComponent extends FC<AnimatedProps> {}

export const Animated: AnimatedComponent = ({ children, className, animate = true }) => {
	const animation = {
		initial: { opacity: 0, y: 100 },
		animate: { opacity: 1, y: 0 },
		exit: { opacity: 0, y: -100 },
	};

	const rootRef = useRef<HTMLElement>(document.documentElement);

	const wrapperClassName = useMemo(
		() => clsx(classes.wrapper, className),
		[className]
	);

	useEffect(() => {
		rootRef.current.scrollTop = 0;
	}, []);

	return (
		<motion.div
			className={wrapperClassName}
			variants={animation}
			initial="initial"
			animate={animate ? "animate" : "initial"}
			exit="exit"
			transition={{ duration: 0.5 }}
		>
			{children}
		</motion.div>
	);
};
