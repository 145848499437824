import { type Dispatch, type FC, type SetStateAction } from "react";
import classes from "./More.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { ProductModel } from "../../../store/productSlice/type";

export interface MoreProps {
	type: string;
	setType: Dispatch<SetStateAction<string>>;
	productItem: ProductModel | undefined;
	id?: string;
}

export interface MoreComponent extends FC<MoreProps> {}

const infoVariants = {
	hidden: { opacity: 0, x: -100 },
	visible: { opacity: 1, x: 0 },
	exit: { opacity: 0, y: 100 },
};


export const More: MoreComponent = ({ type, setType, productItem }) => {	
	const deliveryTextParts = [
		"доставка по Москве силами собственного курьера платформы man I trust / в пределах МКАД - бесплатно",
		"услуга выездной примерки по Москве в пределах МКАД - 1.700 руб / при условии приобретения товара - услуга бесплатна",
		"доставка по России и миру в настоящий момент недоступна / воспользуйтесь функцией <u>уведомить</u>, чтобы первыми узнать об изменениях",
	]

	const payTextParts = [
		"наличный расчет — оплата наличными или переводом осуществляется курьеру, при получении / перед покупкой вы можете осмотреть, примерить и проверить товар в рамках услуги выездной примерки и, если товар вам не подходит, вы имеете право отказаться от его приобретения",
		"возможность оплаты безналичным расчетом на сайте или через терминал в настоящий момент недоступна / воспользуйтесь функцией <u>уведомить</u>, чтобы первыми узнать об изменениях"
	]

	const backTextParts = [
		"на данном этапе, мы не принимаем возвраты после успешного совершения сделки, но предоставляем вам возможность принятия взвешенного решения о приобретении — услугой выездной примерки, отказом от покупки и временным ограничением доставки за пределами Москвы."
	]

	return (
		<AnimatePresence>
			<div className={classes.wrapper}>
				{type === "details" && (
					<motion.div
						className={classes.detail}
						variants={infoVariants}
						initial="hidden"
						animate="visible"
						exit="exit"
					>
						<ul>
							{productItem?.packing && (
								<li className={classes.list}>
									<div className={classes.title}>комплект: </div>
									<span>{productItem?.packing}</span>
								</li>
							)}
							{productItem?.color && (
								<li className={classes.list}>
									<div className={classes.title}>цвет: </div>
									<span>{productItem?.color}</span>
								</li>
							)}
							{productItem?.material && (
								<li className={classes.list}>
									<div className={classes.title}>материал: </div>
									<span>{productItem?.material}</span>
								</li>
							)}
							{productItem?.accessories && (
								<li className={classes.list}>
									<div className={classes.title}>фурнитура: </div>
									<span>{productItem?.accessories}</span>
								</li>
							)}
							{productItem?.gender && (
								<li className={classes.list}>
									<div className={classes.title}>пол: </div>
									<span>{productItem?.gender}</span>
								</li>
							)}
							{productItem?.size && (
								<li className={classes.list}>
									<div className={classes.title}>размер: </div>
									<span>{productItem?.size}</span>
								</li>
							)}
							{productItem?.detailedCondition && (
								<li className={classes.list}>
									<div className={classes.title}>состояние: </div>
									<span>{productItem?.detailedCondition}</span>
								</li>
							)}
							{productItem?.purchaseYear && (
								<li className={classes.list}>
									<div className={classes.title}>год покупки: </div>
									<span>
										{productItem?.purchaseYear}
									</span>
								</li>
							)}{" "}
						</ul>
					</motion.div>
				)}

				{type === "delivery" && (
					<motion.div
						className={classes.detail}
						variants={infoVariants}
						initial="hidden"
						animate="visible"
						exit="exit"
					>
						<div className={classes.description}>
							{deliveryTextParts.map(part => (
								<span dangerouslySetInnerHTML={{
									__html: part
								}}></span>
							))}
						</div>
					</motion.div>
				)}
				{type === "pay" && (
					<motion.div
						className={classes.detail}
						variants={infoVariants}
						initial="hidden"
						animate="visible"
						exit="exit"
					>
						<div className={classes.description}>
							{payTextParts.map(part => (
								<span dangerouslySetInnerHTML={{
									__html: part
								}}></span>
							))}
						</div>
					</motion.div>
				)}
				{type === "back" && (
					<motion.div
						className={classes.detail}
						variants={infoVariants}
						initial="hidden"
						animate="visible"
						exit="exit"
					>
						<div className={classes.description}>
							{backTextParts.map(part => (
								<span dangerouslySetInnerHTML={{
									__html: part
								}}></span>
							))}
						</div>
					</motion.div>
				)}
				{type !== "default" && (
					<button
						className={classes.close}
						onClick={() => setType("default")}
					/>
				)}
			</div>
		</AnimatePresence>
	);
};
