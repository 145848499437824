import { RootState } from "../store";
// import { isNewCondition } from "./helpers"; 

export const selectProductState = (state: RootState) => state.product;

export const selectProductsToShow = (state: RootState) => {
    //TODO: return this filtration logic when
    // const condition = state.product.conditionFilter;

    // if (condition === 'new') {
    //     return state.product.productsToShow.filter((p) => isNewCondition(p.condition));
    // }

    // return state.product.productsToShow.filter((p) => !isNewCondition(p.condition));
    return state.product.productsToShow;
}

export const selectClientCacheProductIds = (state: RootState) => state.product.clientCacheProductIds;

export const isProductIdClientCahched = (productId: number) => (state: RootState) =>
	state.product.clientCacheProductIds.includes(productId);

export const areClientCacheProductIds = (productIds: number[], n?: number) => (state: RootState) => {
    const clientCacheProductIds = state.product.clientCacheProductIds;

    if (clientCacheProductIds.length === 0) {
        return false;
    }

    const productsToCheck = n !== undefined ? productIds.slice(0, Math.min(n, productIds.length)) : productIds;

    if (productsToCheck.length === 0) {
        return false;
    }

    return productsToCheck.every(id => clientCacheProductIds.includes(id));
};

export const isClientCacheProductId = (productId: number) => (state: RootState) => {
    const clientCacheProductIds = state.product.clientCacheProductIds;

    if (clientCacheProductIds.length === 0) {
        return false;
    }

    return clientCacheProductIds.includes(productId);
};

export const removeProductIdsFromClientCache = (productIds: number[]) => (state: RootState) => ({
    ...state,
    product: {
        ...state.product,
        clientCacheProductIds: state.product.clientCacheProductIds.filter(
            (id) => !productIds.includes(id)
        ),
    },
});