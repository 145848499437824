import clsx from "clsx";
import React, { useEffect } from "react";

import classes from "./Pagination.module.scss";
import { useSearchParams } from "react-router-dom";

import { Icon } from "../../Icon/Icon";

interface PaginationProps {
	totalCount: number;
	pageSize: number;
	currentPage: number;
	onPageChange: (page: number) => void;
	onNextPageChange: (page: number) => void;
	className?: string;
}

export const Pagination: React.FC<PaginationProps> = ({
	totalCount,
	pageSize,
	currentPage,
	onPageChange,
	onNextPageChange,
	className,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const totalPageCount = Math.ceil(totalCount / pageSize);

	useEffect(() => {
		const pageFromURL = Number(searchParams.get("page")) || 1;
		onPageChange(pageFromURL);
	}, [searchParams, onPageChange]);

	if (totalCount <= pageSize) {
		return null;
	}

	const handlePageChange = (page: number) => {
		setSearchParams({ page: page.toString() });
		onPageChange(page);
	};

	const onNext = () => {
		if (currentPage !== totalPageCount) {
			handlePageChange(currentPage + 1);
			onNextPageChange(currentPage + 1)
		}
	};

	const onPrevious = () => {
		if (currentPage !== 1) {
			handlePageChange(currentPage - 1);
		}
	};

	return (
		<ul
			className={clsx(classes.paginationContainer, {
				[classes[className || ""]]: className,
			})}
		>
			<li
				className={clsx(classes.paginationItem, {
					[classes.hidden]: currentPage === 1,
				})}
				onClick={onPrevious}>
				<button onClick={onPrevious}>
					<Icon className={classes.iconArrowLeft} name="arrow" />
				</button>
			</li>

			<li>
				{`${currentPage} / ${totalPageCount}`}
			</li>

			<li
				className={clsx(classes.paginationItem, {
					[classes.hidden]: currentPage === totalPageCount,
				})}
				onClick={onNext}>
				<button onClick={onNext}>
					<Icon name="arrow" />
				</button>
			</li>
		</ul>
	);
};
