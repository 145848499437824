export const sortSizesAscending = (sizes: string[]): string[] => {
    return sizes.sort((a, b) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);

        if (isNaN(numA) || isNaN(numB)) {
            return 0;
        }

        return numB - numA;
    });
};


export const sortSizesDescending = (sizes: string[]): string[] => {
    return sizes.sort((a, b) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);

        if (isNaN(numA) || isNaN(numB)) {
            return 0;
        }

        return numA - numB;
    });
};