import { useState, type FC, useMemo, useEffect } from "react";
import classes from "./Header.module.scss";
import { Icon } from "../../shared/Icon/Icon";
import { Navbar } from "../../shared/components/Navbar/Navbar";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../shared/hooks/useAppSelector";
import { cartSelector } from "../../store/cartSlice/selector";
import { favoriteSelector } from "../../store/favoriteSlice/selector";
import { useMatchMedia } from "../../shared/hooks/useMatchMedia";
import clsx from "clsx";
import Sticky from "../Sticky/Sticky"

export const Header: FC = () => {
	const { items } = useAppSelector(cartSelector);
	const { added } = useAppSelector(favoriteSelector);
	const [isNavbarOpen, setIsNavbarOpen] = useState(false);

	const toggleNavbar = () => {
		setIsNavbarOpen(!isNavbarOpen);
	};

	const burgerClassName = useMemo(
		() => clsx(classes.burger, { [classes.open]: isNavbarOpen }),
		[isNavbarOpen]
	);

	const logoClassName = useMemo(
		() => clsx(classes.logo, { [classes.change]: isNavbarOpen }),
		[isNavbarOpen]
	);

	const { isMobile }: any = useMatchMedia();

	useEffect(() => {
		const metaThemeColor = document.querySelector("meta[name=theme-color]");

		if (!isNavbarOpen || !isMobile) {
			metaThemeColor?.setAttribute("content", "#ffffff");
			document.body.style.overflowY = "auto";
			document.removeEventListener("touchmove", preventScroll);
		} else if (isNavbarOpen && isMobile) {
			metaThemeColor?.setAttribute("content", "#000000");
			document.body.style.overflowY = "hidden";
			document.addEventListener("touchmove", preventScroll, { passive: false });
		}

		function preventScroll(event: any) {
			event.preventDefault();
		}

		return () => {
			document.body.style.overflowY = "auto";
			document.removeEventListener("touchmove", preventScroll);
		};
	}, [isNavbarOpen, isMobile]);

	return (
		<Sticky className={classes.header}>
			{!isMobile ? (
				<div className={classes.wrapper}>
					<div className={classes.burger}>
						<button
							className={`${classes.menu} ${isNavbarOpen ? classes.cross : ""}`}
							onClick={toggleNavbar}
						/>
						<Navbar
							className={classes.navbar}
							isOpen={isNavbarOpen}
							onNavbarClose={() => setIsNavbarOpen(false)}
						/>
					</div>
					<div className={classes.logo}>
						<Link className={classes.link} to="/">
							<Icon name="logo" />
						</Link>
					</div>
					<div className={classes.icons}>
						<div className={classes.heart}>
							<Link to="/favorites">
								<Icon
									name="heart"
									onClick={() => setIsNavbarOpen(false)}
									className={classes.icon}
									size={20}
								/>
							</Link>
							<span className={classes.favorites}>{added}</span>
						</div>
						<div className={classes.cart}>
							<Link to="/cart">
								<Icon
									onClick={() => setIsNavbarOpen(false)}
									name="cart"
									className={classes.carticon}
									size={20}
								/>
							</Link>
							<span className={classes.cart}>{items.length}</span>
						</div>
					</div>
				</div>
			) : (
				<div className={classes.mobile}>
					<Link
						className={classes.link}
						to="/"
						onClick={() => setIsNavbarOpen(false)}
					>
						<Icon className={logoClassName} name="logo" size={100} />
					</Link>
					<div className={classes.right}>
						<div className={classes.heart}>
							<Link to="/favorites">
								<Icon
									name="heart"
									onClick={() => setIsNavbarOpen(false)}
									className={classes.icon}
									size={20}
								/>
							</Link>
							<span className={classes.favorites}>{added}</span>
						</div>
						<div className={classes.cart}>
							<Link to="/cart">
								<Icon
									onClick={() => setIsNavbarOpen(false)}
									name="cart"
									className={classes.carticon}
									size={20}
								/>
							</Link>
							<span className={classes.cart}>{items.length}</span>
						</div>

						<div className={burgerClassName}>
							<button
								className={`${classes.menu} ${
									isNavbarOpen ? classes.cross : ""
								}`}
								onClick={toggleNavbar}
							/>
							<Navbar
								className={classes.navbar}
								isOpen={isNavbarOpen}
								onNavbarClose={() => setIsNavbarOpen(false)}
							/>
						</div>
					</div>
				</div>
			)}
		</Sticky>
	);
};
