import { useEffect, useMemo, useState } from "react";
import classes from "./ProductList.module.scss";

import { Animated } from "../../shared/components/Animated/Animated";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../shared/hooks/useAppSelector";
import { selectProductState, selectProductsToShow } from "../../store/productSlice/selector";
import { clearClientCacheProductIds } from "../../store/productSlice/slice"
import { useAppDispatch } from "../../shared/hooks/useAppDispatch";
import { fetchAllProducts, fetchDiscountedProducts, fetchProductsFromBrand, fetchProductsFromCategory } from "../../store/productSlice/thunk";

import { Pagination } from "../../shared/components/Pagination/Pagination";
import { Loader } from "../../shared/components/Loader/Loader";
import { Empty } from "../../shared/components/Empty/Empty";
import { categorySelector } from "../../store/categorySlice/selector";
import { fetchCategory } from "../../store/categorySlice/thunk";
import { fetchBrand } from "../../store/brandSlice/thunk";
import { ParentType, ProductItem } from "../../shared/components/ProductItem/ProductItem";
import { ProductModel } from "../../store/productSlice/type";
import { getImagePath } from "../../services/image-path";
import { brandSelector } from "../../store/brandSlice/selector";
import { areClientCacheProductIds, selectClientCacheProductIds } from "../../store/productSlice/selector"
import { useMatchMedia } from "../../shared/hooks/useMatchMedia";
import clsx from "clsx";

export interface BrandType {
	id: number;
	image: string;
	quality: string;
	price: number;
	brand: string;
	name: string;
}

type ProductListProps = {
	loadByCategory?: boolean,
	loadByBrand?: boolean,
	loadDiscounted?: boolean,
	loadAll?: boolean,
}

export const ProductList = (props: ProductListProps) => {
	const { loadByCategory, loadByBrand, loadDiscounted, loadAll } = props;
	const dispatch = useAppDispatch();

	const { loading } = useAppSelector(selectProductState);
	const productsToShow = useAppSelector(selectProductsToShow);
	const { category: categories } = useAppSelector(categorySelector);
	const { brand: brands } = useAppSelector(brandSelector);

	const [currentPage, setCurrentPage] = useState(1);
	let pageSize = 12;

	const indexOfLastBrand = currentPage * pageSize;
	const indexOfFirstBrand = indexOfLastBrand - pageSize;
	const currentProducts = productsToShow.slice(indexOfFirstBrand, indexOfLastBrand);

	const { id } = useParams();

	const { isMobile }: any = useMatchMedia();

	const headline = useMemo(() => {
		if (loadAll) {
			return 'все';
		}

		if (loadByCategory) {
			const category = categories.find((c) => c.id === Number(id));
			return category?.name
		}

		if (loadByBrand) {
			const brand = brands.find((c) => c.id === Number(id));
			return brand?.name
		}

		if (loadDiscounted) {
			return 'sale'
		}

		return 'Все товары';
	}, [brands, categories, id, loadAll, loadByBrand, loadByCategory, loadDiscounted]);

	const loadFrom = useMemo<ParentType | undefined>(() => {
		if (loadByCategory) {
			return {
				type: 'category',
				id: Number(id),
			}
		}

		if (loadByBrand) {
			return {
				type: 'brand',
				id: Number(id),
			}
		}

		return undefined;
	}, [id, loadByBrand, loadByCategory]);

	const onNextPageChange = (page: number) => {
		window.scrollTo({ top: 0 });
		// return
	}

	useEffect(() => {
		dispatch(clearClientCacheProductIds());

		if (loadAll) {
			dispatch(fetchAllProducts());
			return;
		}

		if (loadByCategory) {
			dispatch(fetchCategory());
			dispatch(fetchProductsFromCategory(Number(id)));
			return;
		}

		if (loadByBrand) {
			dispatch(fetchBrand());
			dispatch(fetchProductsFromBrand(Number(id)));
			return;
		}

		if (loadDiscounted) {
			dispatch(fetchDiscountedProducts());
			return;
		}
		dispatch(fetchAllProducts());
	}, [dispatch, id, loadAll, loadByBrand, loadByCategory, loadDiscounted]);

	const listClassName = useMemo(
		() =>
			clsx(classes.productList, {
				[classes.one]: currentProducts.length === 1,
			}),
		[currentProducts]
	);

	const productIds = currentProducts.map((product) => product.id);
	const areImagesLoaded = useAppSelector(areClientCacheProductIds(productIds, 3));

	const isLoading = (loading === "success") && areImagesLoaded;
	const isPreparedCatalog = useMemo(() => isLoading, [isLoading]);

	return (
		<>
			{
				!isPreparedCatalog ? (
					<div className={classes.loader}>
						<Loader />
					</div>
				) : (
					<></>
				)
			}
			<Animated className={classes.wrapper} animate={isPreparedCatalog}>
				{!!productsToShow.length ? (
					<>
						<h1 className={classes.title}>{headline}</h1>
						<div className={classes.productListWrapper}>
							<ul className={listClassName}>
								{currentProducts.map((item: ProductModel, index: number) => {
									const hasDiscount = item.discount && !item.discount.promocode;
									const discountPercent = hasDiscount ? item.discount?.percentDiscount : undefined;

									return (
										<ProductItem
											className={classes.brand}
											brand={item.brand.name}
											key={index}
											index={index + 1}
											productId={item.id}
											quality={item.condition}
											image={getImagePath(item.images[0].url)}
											inStock={!!!item.order && !item.isSold}
											price={item.price}
											discountPercent={discountPercent}
											title={item.title}
											loadFrom={loadFrom}
											isOnlyItem={currentProducts.length <= 1}
										/>
									);
								})}
							</ul>
						</div>

						<Pagination
							currentPage={currentPage}
							totalCount={productsToShow.length}
							pageSize={pageSize}
							onPageChange={(page: number) => setCurrentPage(page)}
							onNextPageChange={(page: number) => onNextPageChange(page)}
						/>
					</>
				) : (
					<Empty>к сожалению, здесь пока пусто</Empty>
				)}
			</Animated>
		</>
	);
};
